<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <!-- <s-toolbar :color="'#244093'" dark label="Reporte de Usos" class="tran"></s-toolbar> -->
                    <v-card>

                        <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                            <v-col cols="4" lg="4" md="4">
                                <s-text label="Ingrese año inicio" number v-model="txtYearInitial"></s-text>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-text label="Ingrese año fin" number v-model="txtYearFin"></s-text>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                <v-btn width="100%" rounded :color="'info'" small @click="run()">Buscar</v-btn>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                <v-spacer></v-spacer>
                                <v-btn @click="show = !show" rounded :color="'warning'" small>
                                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>Descargar ° Ver
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-card flat>
                <v-expand-transition>
                    <div v-show="show">
                        <v-divider></v-divider>
                        <v-row justify="center" style="">

                            <v-col cols="12" lg="12" md="12" class="mt-3 ml-5">
                                <v-btn outlined rounded :color="'error'" small @click="downloadOne()">Un Solo .xls</v-btn>
                                <v-btn outlined rounded :color="'error'" small @click="downloadAll()">Varios .xls</v-btn>

                                <v-btn outlined rounded :color="'info'" small @click="viewDasboardCV(1)">Visualizaciòn Grafica CV</v-btn>
                                <v-btn outlined rounded :color="'success'" small @click="viewDasboardCV(2)">Visualizaciòn Grafica RE</v-btn>
                            </v-col>

                        </v-row>

                    </div>
                </v-expand-transition>

            </v-card>

            <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
                <v-card color="primary" dark>
                    <v-card-text v-if="messageProcessing.length == 0">
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text--white pt-4">
                            Error al realizar busqueda <br />
                            {{ messageProcessing }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- <v-dialog v-if="openModalGraphMonth" v-model="openModalGraphMonth" width="50%">
                <v-card>
                    <v-row style="margin-top: 0px; margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1">
                                <v-row>
                                    <v-col cols="12">
                                        <use-card-line-chart-month :itemsY="itemsMonth" :anio="CboAnio" :nameOption="nameOption"></use-card-line-chart-month>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog> -->

            <v-dialog v-if="openModalGraph" v-model="openModalGraph" persistent>
                <v-card>
                    <v-row style="margin: auto">
                        <!-- <h3 class="mt-3 ml-6"></h3>
                        <v-spacer> </v-spacer> -->
                        <!-- <v-btn @click="openModalGraph = false" class="mt-3 mr-3" small fab color="error"><i style="font-size: 16px" class="fas fa-times"></i></v-btn> -->
                        <s-toolbar :color="'#244093'" close @close="openModalGraph = false" dark class="tran"></s-toolbar>
                    </v-row>

                    <!-- <v-row style="margin-top: 0px; margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1">
                                <v-row>
                                    <v-col cols="6" v-if="itemsYLote.length > 0">
                                        <use-card-line-chart-lote :itemsX="itemsXLote" :itemsY="itemsYLote"></use-card-line-chart-lote>
                                        <v-select v-model="CboAnio" @input="input($event, 1)" placeholder="Seleccione opción" :items="itemsXLote" dense outlined :item-value="'Anioventa'" :item-text="'Anioventa'" />
                                    </v-col>
                                    <v-col cols="6" v-if="itemsY.length > 0">
                                        <use-card-line-chart-area :itemsX="itemsX" :itemsY="itemsY"></use-card-line-chart-area>
                                        <v-select v-model="CboAnio" @input="input($event, 2)" placeholder="Seleccione opción" :items="itemsX" dense outlined :item-value="'Anioventa'" :item-text="'Anioventa'" />
                                    </v-col>
                                    <v-col cols="6" v-if="itemsYCapital.length > 0">
                                        <use-card-line-chart-capital :itemsX="itemsXCapital" :itemsY="itemsYCapital"></use-card-line-chart-capital>
                                        <v-select v-model="CboAnio" @input="input($event, 3)" placeholder="Seleccione opción" :items="itemsXCapital" dense outlined :item-value="'Anioventa'" :item-text="'Anioventa'" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row> -->
                </v-card>
            </v-dialog>

            <v-dialog v-if="openModalGraphRE" v-model="openModalGraphRE" persistent hide-overlay width="100%" fullscreen>
                <v-card>
                    <v-row style="margin: auto">
                        <h3 class="mt-3 ml-6"></h3>
                        <v-spacer> </v-spacer>
                        <v-btn @click="openModalGraphRE = false" class="mt-3 mr-3" small fab color="error"><i style="font-size: 16px" class="fas fa-times"></i></v-btn>
                    </v-row>

                    <v-row style="margin-top: 0px;margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1">
                                <v-row>
                                    <v-col cols="6">
                                        <use-card-line-chart-lotere :itemsX="itemsXLoteRE" :itemsY="itemsYLoteRE"></use-card-line-chart-lotere>
                                    </v-col>
                                    <v-col cols="6">
                                        <use-card-line-chart-areare :itemsX="itemsXRE" :itemsY="itemsYRE"></use-card-line-chart-areare>
                                    </v-col>
                                    <v-col cols="6">
                                        <use-card-line-chart-capitalre :itemsX="itemsXCapitalRE" :itemsY="itemsYCapitalRE"></use-card-line-chart-capitalre>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>

        </v-container>
        <v-container>
            <v-tabs v-model="currentItem">
                <v-tab href="#DashboardCV"> Dashboard CV</v-tab>

                <v-tab href="#GeneralCv"> General CV | RE</v-tab>
                <v-tab href="#BatchCv"> Lote CV</v-tab>
                <v-tab href="#AreaCv"> Area CV</v-tab>
                <v-tab href="#CapitalCv"> Capital CV</v-tab>
                <v-tab href="#DashboardRE"> Dashboard RE</v-tab>
                <v-tab href="#BatchRe"> Lote RE</v-tab>
                <v-tab href="#AreaRe"> Area RE</v-tab>
                <v-tab href="#CapitalRe"> Capital RE</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'DashboardCV'">
                    <v-col cols="12" lg="12" md="12" class="mt-0 ml-5" style="margin-bottom:-20px">

                        <v-radio-group v-model="radios">
                            <template v-slot:label>
                                <div>Seleccione Tipo de reporte</div>
                            </template>
                            <v-radio value="1">
                                <template v-slot:label>
                                    <div>Grafico <strong class="error--text">Pastel</strong></div>
                                </template>
                            </v-radio>
                            <v-radio value="2" style="margin-top: -10px">
                                <template v-slot:label>
                                    <div>Grafico <strong class="primary--text">Barras</strong></div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>

                    <clc-report-dasboard v-if="radios==1" :itemsLot="dataDashboardLoteCV" :itemsArea="dataDashboardAreaCV" :itemsCapital="dataDashboardCapitalCV"></clc-report-dasboard>

                    <clc-report-dasboard-drill v-if="radios==2" :itemsLot="dataDashboardLoteCV" :objDrilldownLot="objDrilldown" :itemsArea="dataDashboardAreaCV" :objDrilldownArea="objDrilldownArea" :itemsCapital="dataDashboardCapitalCV" :objDrilldownCapital="objDrilldownCapital">

                    </clc-report-dasboard-drill>

                </v-tab-item>

                <v-tab-item :value="'GeneralCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="itemsGeneral.length > 0 ? '300px': 'auto'" :headers="headersGeneral" :items="itemsGeneral" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'BatchCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersBatchcv" :items="itemsBatchcv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'AreaCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersAreacv" :items="itemsAreacv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'CapitalCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersCapitalcv" :items="itemsCapitalcv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'BatchRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersBatchre" :items="itemsBatchre" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'AreaRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersAreare" :items="itemsAreare" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'CapitalRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersCapitalre" :items="itemsCapitalre" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-container>
</div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import * as XLSX from 'xlsx';

/* import UseCardLineChartArea from './Dashboard/UseCardLineChartArea.vue';
import UseCardLineChartCapital from './Dashboard/UseCardLineChartCapital.vue';
import UseCardLineChartLote from './Dashboard/UseCardLineChartLote.vue'; */

/* import UseCardLineChartAreare from './Dashboard/UseCardLineChartAreare.vue';
import UseCardLineChartCapitalre from './Dashboard/UseCardLineChartCapitalre.vue';
import UseCardLineChartLotere from './Dashboard/UseCardLineChartLotere.vue';
import UseCardLineChartMonth from './Dashboard/UseCardLineChartMonth.vue'; */

import ClcReportDasboard from './ClcReportDasboard.vue';
import ClcReportDasboardDrill from './ClcReportDasboardDrill.vue';

export default {
    components: {

        /* UseCardLineChartArea,
        UseCardLineChartCapital,
        UseCardLineChartLote,

        UseCardLineChartAreare,
        UseCardLineChartCapitalre,
        UseCardLineChartLotere,
        UseCardLineChartMonth, */
        ClcReportDasboard,
        ClcReportDasboardDrill,

    },

    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            messageProcessing: "",
            txtYearFin: this.$fun.getYear(),
            txtYearInitial: 2012,

            itemsGeneral: [],

            itemsBatchcv: [],
            itemsAreacv: [],
            itemsCapitalcv: [],

            itemsBatchre: [],
            itemsAreare: [],
            itemsCapitalre: [],

            headersGeneral: [],
            headersBatchcv: [],
            headersAreacv: [],
            headersCapitalcv: [],

            headersBatchre: [],
            headersAreare: [],
            headersCapitalre: [],

            itemsAll: [],
            show: false,

            //Variables para Dashboard
            groupAreaCv: [],
            dataDashboardAreaCV: [],

            groupCapitalCv: [],
            dataDashboardCapitalCV: [],

            groupLoteCv: [],
            dataDashboardLoteCV: [],

            groupAreaRe: [],
            dataDashboardAreaRE: [],

            groupCapitalRe: [],
            dataDashboardCapitalRE: [],

            groupLoteRe: [],
            dataDashboardLoteRE: [],
            openModalGraph: false,
            openModalGraphRE: false,

            itemsX: [],
            itemsY: [],

            itemsXCapital: [],
            itemsYCapital: [],

            itemsXLote: [],
            itemsYLote: [],

            itemsXRE: [],
            itemsYRE: [],

            itemsXCapitalRE: [],
            itemsYCapitalRE: [],

            itemsXLoteRE: [],
            itemsYLoteRE: [],
            openModalGraphMonth: false,
            itemsMonth: [],
            CboAnio: null,
            xFlagVisible: null,
            nameOption: null,
            objDrilldown: [],
            objDrilldownArea: [],
            objDrilldownCapital: [],
            radios: null

        }

    },

    methods: {

        run() {
            if (this.radios == null) {
                this.$fun.alert("Seleccione un tipo de reporte", "warning")
                return
            }

            this.runView()
            this.runView2()
            this.runView3()
            this.runView4()
            this.runView5()
            this.runView6()
            this.runViewGeneral()
        },

        runViewGeneral() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseGeneralCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsGeneral = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsGeneral[0]).length; i++
                            ) {
                                this.headersGeneral.splice(i, i, {
                                    text: Object.keys(this.itemsGeneral[0])[i],
                                    value: Object.keys(this.itemsGeneral[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseBacthCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsBatchcv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsBatchcv[0]).length; i++
                            ) {
                                this.headersBatchcv.splice(i, i, {
                                    text: Object.keys(this.itemsBatchcv[0])[i],
                                    value: Object.keys(this.itemsBatchcv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = [],
                                objTmpdrilldown = {}

                            this.dataDashboardLoteCV = []
                            this.objDrilldown = []

                            this.groupLoteCv = _.groupBy(this.itemsBatchcv, "Anioventa");

                            for (let prop in this.groupLoteCv) {
                                newItems.push(this.itemsBatchcv.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            // console.log(this.groupLoteCv, newItems)

                            this.groupLoteCv = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupLoteCv) {

                                var sum = 0;
                                objTmp = {}
                                objTmpdrilldown = {}

                                this.groupLoteCv[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                //GRAFICO DE LINEAS
                                objTmp.Anioventa = prop
                                objTmp.Total = sum

                                //GRAFICO PIE AÑOS
                                objTmp.name = prop
                                objTmp.y = sum
                                objTmp.drilldown = prop

                                //GRAFICO PIE MESES
                                objTmpdrilldown.id = prop
                                objTmpdrilldown.name = prop
                                objTmpdrilldown.data = []

                                this.dataDashboardLoteCV.push(objTmp)
                                this.objDrilldown.push(objTmpdrilldown)

                                this.groupLoteCv[prop].map((i) => {
                                    objTmpdrilldown.data.push(["Enero", i.Enero])
                                    objTmpdrilldown.data.push(["Febrero", i.Febrero])
                                    objTmpdrilldown.data.push(["Marzo", i.Marzo])
                                    objTmpdrilldown.data.push(["Abril", i.Abril])
                                    objTmpdrilldown.data.push(["Mayo", i.Mayo])
                                    objTmpdrilldown.data.push(["Junio", i.Junio])
                                    objTmpdrilldown.data.push(["Julio", i.Julio])
                                    objTmpdrilldown.data.push(["Agosto", i.Agosto])
                                    objTmpdrilldown.data.push(["Septiembre", i.Septiembre])
                                    objTmpdrilldown.data.push(["Octubre", i.Octubre])
                                    objTmpdrilldown.data.push(["Noviembre", i.Noviembre])
                                    objTmpdrilldown.data.push(["Diciembre", i.Diciembre])

                                });
                            }

                            //************************** FIN  *************************************/

                            this.itemsXLote = []
                            this.itemsYLote = []
                            this.dataDashboardLoteCV.forEach(element => {
                                this.itemsXLote.push(element.Anioventa)
                                this.itemsYLote.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView2() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseAreaCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsAreacv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsAreacv[0]).length; i++
                            ) {
                                this.headersAreacv.splice(i, i, {
                                    text: Object.keys(this.itemsAreacv[0])[i],
                                    value: Object.keys(this.itemsAreacv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = [],
                                objTmpdrilldown = {}

                            this.dataDashboardAreaCV = []
                            this.objDrilldownArea = []

                            this.groupAreaCv = _.groupBy(this.itemsAreacv, "Anioventa");

                            for (let prop in this.groupAreaCv) {
                                newItems.push(this.itemsAreacv.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            this.groupAreaCv = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupAreaCv) {
                                var sum = 0;
                                objTmp = {}
                                objTmpdrilldown = {}

                                this.groupAreaCv[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                objTmp.Anioventa = prop
                                objTmp.Total = sum

                                //GRAFICO PIE AÑOS
                                objTmp.name = prop
                                objTmp.y = sum
                                objTmp.drilldown = prop

                                //GRAFICO PIE MESES
                                objTmpdrilldown.id = prop
                                objTmpdrilldown.name = prop
                                objTmpdrilldown.data = []

                                this.dataDashboardAreaCV.push(objTmp)
                                this.objDrilldownArea.push(objTmpdrilldown)

                                this.groupAreaCv[prop].map((i) => {
                                    objTmpdrilldown.data.push(["Enero", i.Enero])
                                    objTmpdrilldown.data.push(["Febrero", i.Febrero])
                                    objTmpdrilldown.data.push(["Marzo", i.Marzo])
                                    objTmpdrilldown.data.push(["Abril", i.Abril])
                                    objTmpdrilldown.data.push(["Mayo", i.Mayo])
                                    objTmpdrilldown.data.push(["Junio", i.Junio])
                                    objTmpdrilldown.data.push(["Julio", i.Julio])
                                    objTmpdrilldown.data.push(["Agosto", i.Agosto])
                                    objTmpdrilldown.data.push(["Septiembre", i.Septiembre])
                                    objTmpdrilldown.data.push(["Octubre", i.Octubre])
                                    objTmpdrilldown.data.push(["Noviembre", i.Noviembre])
                                    objTmpdrilldown.data.push(["Diciembre", i.Diciembre])

                                });

                            }
                            //************************** FIN  *************************************/

                            this.itemsX = []
                            this.itemsY = []
                            this.dataDashboardAreaCV.forEach(element => {
                                this.itemsX.push(element.Anioventa)
                                this.itemsY.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView3() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseCapitalCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsCapitalcv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsCapitalcv[0]).length; i++
                            ) {
                                this.headersCapitalcv.splice(i, i, {
                                    text: Object.keys(this.itemsCapitalcv[0])[i],
                                    value: Object.keys(this.itemsCapitalcv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = [],
                                objTmpdrilldown = {}

                            this.dataDashboardCapitalCV = []
                            this.objDrilldownCapital = []

                            this.groupCapitalCv = _.groupBy(this.itemsCapitalcv, "Anioventa");

                            for (let prop in this.groupCapitalCv) {
                                newItems.push(this.itemsCapitalcv.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            this.groupCapitalCv = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupCapitalCv) {
                                var sum = 0;
                                objTmp = {}
                                objTmpdrilldown = {}

                                this.groupCapitalCv[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                objTmp.Anioventa = prop
                                objTmp.Total = sum

                                //GRAFICO PIE AÑOS
                                objTmp.name = prop
                                objTmp.y = sum
                                objTmp.drilldown = prop

                                //GRAFICO PIE MESES
                                objTmpdrilldown.id = prop
                                objTmpdrilldown.name = prop
                                objTmpdrilldown.data = []

                                this.dataDashboardCapitalCV.push(objTmp)
                                this.objDrilldownCapital.push(objTmpdrilldown)

                                this.groupCapitalCv[prop].map((i) => {
                                    objTmpdrilldown.data.push(["Enero", i.Enero])
                                    objTmpdrilldown.data.push(["Febrero", i.Febrero])
                                    objTmpdrilldown.data.push(["Marzo", i.Marzo])
                                    objTmpdrilldown.data.push(["Abril", i.Abril])
                                    objTmpdrilldown.data.push(["Mayo", i.Mayo])
                                    objTmpdrilldown.data.push(["Junio", i.Junio])
                                    objTmpdrilldown.data.push(["Julio", i.Julio])
                                    objTmpdrilldown.data.push(["Agosto", i.Agosto])
                                    objTmpdrilldown.data.push(["Septiembre", i.Septiembre])
                                    objTmpdrilldown.data.push(["Octubre", i.Octubre])
                                    objTmpdrilldown.data.push(["Noviembre", i.Noviembre])
                                    objTmpdrilldown.data.push(["Diciembre", i.Diciembre])

                                });
                            }

                            //************************** FIN  *************************************/

                            this.itemsXCapital = []
                            this.itemsYCapital = []
                            this.dataDashboardCapitalCV.forEach(element => {
                                this.itemsXCapital.push(element.Anioventa)
                                this.itemsYCapital.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView4() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseBacthRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsBatchre = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsBatchre[0]).length; i++
                            ) {
                                this.headersBatchre.splice(i, i, {
                                    text: Object.keys(this.itemsBatchre[0])[i],
                                    value: Object.keys(this.itemsBatchre[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = []
                            this.dataDashboardLoteRE = []

                            this.groupLoteRe = _.groupBy(this.itemsBatchre, "Anioventa");

                            for (let prop in this.groupLoteRe) {
                                newItems.push(this.itemsBatchre.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            this.groupLoteRe = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupLoteRe) {
                                var sum = 0;
                                objTmp = {}
                                this.groupLoteRe[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                objTmp.Anioventa = prop
                                objTmp.Total = sum
                                this.dataDashboardLoteRE.push(objTmp)
                            }

                            //************************** FIN  *************************************/

                            this.itemsXLoteRE = []
                            this.itemsYLoteRE = []
                            this.dataDashboardLoteRE.forEach(element => {
                                this.itemsXLoteRE.push(element.Anioventa)
                                this.itemsYLoteRE.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView5() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseAreaRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsAreare = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsAreare[0]).length; i++
                            ) {
                                this.headersAreare.splice(i, i, {
                                    text: Object.keys(this.itemsAreare[0])[i],
                                    value: Object.keys(this.itemsAreare[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = []
                            this.dataDashboardAreaRE = []

                            this.groupAreaRe = _.groupBy(this.itemsAreare, "Anioventa");

                            for (let prop in this.groupAreaRe) {
                                newItems.push(this.itemsAreare.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            this.groupAreaRe = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupAreaRe) {
                                var sum = 0;
                                objTmp = {}
                                this.groupAreaRe[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                objTmp.Anioventa = prop
                                objTmp.Total = sum
                                this.dataDashboardAreaRE.push(objTmp)
                            }

                            //************************** FIN  *************************************/

                            this.itemsXRE = []
                            this.itemsYRE = []
                            this.dataDashboardAreaRE.forEach(element => {
                                this.itemsXRE.push(element.Anioventa)
                                this.itemsYRE.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView6() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseCapitalRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsCapitalre = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsCapitalre[0]).length; i++
                            ) {
                                this.headersCapitalre.splice(i, i, {
                                    text: Object.keys(this.itemsCapitalre[0])[i],
                                    value: Object.keys(this.itemsCapitalre[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sum = 0,
                                objTmp = {},
                                newItems = []
                            this.dataDashboardCapitalRE = []

                            this.groupCapitalRe = _.groupBy(this.itemsCapitalre, "Anioventa");

                            for (let prop in this.groupCapitalRe) {
                                newItems.push(this.itemsCapitalre.filter(x => x.ProyecCorto == 'TOTAL' + prop)[0])
                            }

                            this.groupCapitalRe = _.groupBy(newItems, "Anioventa");

                            for (let prop in this.groupCapitalRe) {
                                var sum = 0;
                                objTmp = {}
                                this.groupCapitalRe[prop].map((i) => {
                                    sum = sum + i.Total
                                });

                                objTmp.Anioventa = prop
                                objTmp.Total = sum
                                this.dataDashboardCapitalRE.push(objTmp)
                            }

                            //************************** FIN  *************************************/

                            this.itemsXCapitalRE = []
                            this.itemsYCapitalRE = []
                            this.dataDashboardCapitalRE.forEach(element => {
                                this.itemsXCapitalRE.push(element.Anioventa)
                                this.itemsYCapitalRE.push(element.Total)
                            });

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReportGeneral() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseGeneralCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! General CV|RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseBacthCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Lotes CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport2() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseAreaCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Area CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport3() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseCapitalCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Capital CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport4() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseBacthRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Lotes RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport5() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseAreaRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Area RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport6() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseCapitalRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Capital RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadAll() {
            this.downloadReport()
            this.downloadReport2()
            this.downloadReport3()
            this.downloadReport4()
            this.downloadReport5()
            this.downloadReport6()
            this.downloadReportGeneral()
        },

        downloadOne() {

            const workSheet1 = XLSX.utils.json_to_sheet(this.itemsBatchcv);
            const workSheet2 = XLSX.utils.json_to_sheet(this.itemsAreacv);
            const workSheet3 = XLSX.utils.json_to_sheet(this.itemsCapitalcv);

            const workSheet4 = XLSX.utils.json_to_sheet(this.itemsBatchre);
            const workSheet5 = XLSX.utils.json_to_sheet(this.itemsAreare);
            const workSheet6 = XLSX.utils.json_to_sheet(this.itemsCapitalre);

            const workSheet7 = XLSX.utils.json_to_sheet(this.itemsGeneral);

            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet7, "General CV|RE");

            XLSX.utils.book_append_sheet(workBook, workSheet1, "Lote Cv");
            XLSX.utils.book_append_sheet(workBook, workSheet2, "Area Cv");
            XLSX.utils.book_append_sheet(workBook, workSheet3, "Capital Cv");

            XLSX.utils.book_append_sheet(workBook, workSheet4, "Lote Re");
            XLSX.utils.book_append_sheet(workBook, workSheet5, "Area Re");
            XLSX.utils.book_append_sheet(workBook, workSheet6, "Capital Re");

            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });

            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "ReporteUsos.xlsx");

        },

        viewDasboardCV(item) {
            if (item == 1) {
                this.openModalGraph = true
            } else {
                this.openModalGraphRE = true
            }

        },

        input(item, xFlag) {
            console.log("al seleccionar", item, xFlag)

            this.xFlagVisible = xFlag

            var newItems = [],
                obj = {}
            this.itemsMonth = []
            this.nameOption = ''

            if (xFlag == 1) {
                newItems = (this.itemsBatchcv.filter(x => x.Anioventa == item).filter(x => x.ProyecCorto == 'TOTAL' + item))
                obj = newItems[0]
                this.itemsMonth = [obj.Enero, obj.Febrero, obj.Marzo, obj.Abril, obj.Mayo, obj.Junio, obj.Julio, obj.Agosto, obj.Septiembre, obj.Octubre, obj.Noviembre, obj.Diciembre]

                this.nameOption = "LOTES"
                this.openModalGraphMonth = true
            }

            if (xFlag == 2) {
                newItems = (this.itemsAreacv.filter(x => x.Anioventa == item).filter(x => x.ProyecCorto == 'TOTAL' + item))
                obj = newItems[0]
                this.itemsMonth = [obj.Enero, obj.Febrero, obj.Marzo, obj.Abril, obj.Mayo, obj.Junio, obj.Julio, obj.Agosto, obj.Septiembre, obj.Octubre, obj.Noviembre, obj.Diciembre]
                this.nameOption = "AREA"
                this.openModalGraphMonth = true
            }

            if (xFlag == 3) {

                newItems = (this.itemsCapitalcv.filter(x => x.Anioventa == item).filter(x => x.ProyecCorto == 'TOTAL' + item))
                obj = newItems[0]
                this.itemsMonth = [obj.Enero, obj.Febrero, obj.Marzo, obj.Abril, obj.Mayo, obj.Junio, obj.Julio, obj.Agosto, obj.Septiembre, obj.Octubre, obj.Noviembre, obj.Diciembre]
                this.nameOption = "CAPITAL"
                this.openModalGraphMonth = true
            }
        }

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
