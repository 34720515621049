<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <s-toolbar :color="'#244093'" dark label="Reporte de Usos" class="tran"></s-toolbar>
                    <v-card>

                        <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition :def="1141" label="Tipo" @input="inputType($event)"></s-select-definition>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>

    </v-container>

    <clc-report-use-dinamic v-if="visible==true" style="margin-top: -45px"></clc-report-use-dinamic>
    <clc-report-use-carcode v-if="visible==false" style="margin-top: -45px"></clc-report-use-carcode>
</div>
</template>

<script>
import ClcReportUseCarcode from './ClcReportUseCarcode.vue';
import ClcReportUseDinamic from './ClcReportUseDinamic.vue';
export default {
    components: {
        ClcReportUseCarcode,
        ClcReportUseDinamic
    },

    data() {
        return {
            visible: true
        }

    },

    methods: {

        inputType(item) {
            if (item !== undefined) {
                if (item == 1) {
                    this.visible = true
                } else {
                    this.visible = false
                }

            }

        },

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
